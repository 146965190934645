import { useEffect } from "react";
import { StatsContainer } from "../../components";
import { useDispatch } from "react-redux";
import { showStats } from "../../features/allJobs/allJobsSlice";
import { getAllJobs } from "../../features/allJobs/allJobsSlice";
const Stats = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllJobs());
    dispatch(showStats());
  }, []);
  return (
    <>
      <StatsContainer />
    </>
  );
};
export default Stats;
