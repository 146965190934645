import StatItem from "./StatItem";
import { FaSuitcaseRolling, FaCalendarCheck, FaBug } from "react-icons/fa";
import Wrapper from "../assets/wrappers/StatsContainer";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const StatsContainer = () => {
  const { stats, jobs } = useSelector((store) => store.allJobs);
  const { user } = useSelector((store) => store.user);
  const assignedJobs = useMemo(
    () => jobs?.filter((job) => typeof job?.assigned === "string"),
    [jobs]
  );
  const completedJobs = useMemo(
    () => jobs?.filter((job) => job?.status === "completed"),
    [jobs]
  );

  const defaultStats = [
    {
      title: "Jobs Applied",
      count: stats?.applied_jobs?.length || 0,
      icon: <FaSuitcaseRolling />,
      color: "#e9b949",
      bcg: "#fcefc7",
    },
    {
      title: "Assigned Jobs",
      count: stats?.assigned_job?.length || 0,
      icon: <FaCalendarCheck />,
      color: "#647acb",
      bcg: "#e0e8f9",
    },
    {
      title: "Completed Completed",
      count: stats?.completed_jobs?.length || 0,
      icon: <FaBug />,
      color: "#d66a6a",
      bcg: "#ffeeee",
    },
  ];
  const adMinDefaultStats = [
    {
      title: "Created Jobs",
      count: jobs?.length || 0,
      icon: <FaSuitcaseRolling />,
      color: "#e9b949",
      bcg: "#fcefc7",
    },
    {
      title: "Assigned Jobs",
      count: assignedJobs?.length || 0,
      icon: <FaCalendarCheck />,
      color: "#647acb",
      bcg: "#e0e8f9",
    },
    {
      title: "Job Completed",
      count: completedJobs?.length || 0,
      icon: <FaBug />,
      color: "#d66a6a",
      bcg: "#ffeeee",
    },
  ];
  return (
    <Wrapper>
      {user?.isAdmin
        ? adMinDefaultStats?.map((item, index) => {
            return <StatItem key={index} {...item} />;
          })
        : defaultStats.map((item, index) => {
            return <StatItem key={index} {...item} />;
          })}
    </Wrapper>
  );
};
export default StatsContainer;
