import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../assets/wrappers/HealthCare";
import ModalWrapper from "../../assets/wrappers/CareerModalInfo";
import Loading from "../../components/Loading";
import Modal from "../../components/CareerModal";
import { deleteCareer, getCareer } from "../../features/forms/formSlice";

const Career = () => {
  const { career, isLoading } = useSelector((store) => store.forms);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCareer());
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Wrapper>
      <div className="healthcare">
        <h1>Career Forms</h1>
        <div className="form_table">
          <div className="form_header">
            <h4>All Submitted Career Forms</h4>
          </div>
          <div className="form_body">
            <table className=" table ">
              <thead className="table_head">
                <tr>
                  <th scope="col">
                    <p>Name</p>
                  </th>
                  <th scope="col">
                    <p>Email</p>
                  </th>
                  <th scope="col">
                    <p>Phone</p>
                  </th>
                  <th scope="col">
                    <p>Location</p>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="table_body">
                {career?.map((info, index) => (
                  <tr key={index}>
                    <td>
                      <h5>{info?.fullname ?? ""}</h5>
                    </td>
                    <td>
                      <p>{info?.email}</p>
                    </td>
                    <td>
                      <p>{info?.phone}</p>
                    </td>
                    <td>
                      <p>
                        {info?.address
                          ? info?.address
                          : info?.state
                          ? info?.state
                          : info?.country}
                      </p>
                    </td>

                    <td>
                      <div className="buttons_cta">
                        <button
                          onClick={() => {
                            setSelectedInfo(info);
                            setModalOpen(true);
                          }}
                          className="open"
                        >
                          Open
                        </button>
                        <button
                          onClick={() => dispatch(deleteCareer(info?._id))}
                          className="del"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal open={modalOpen} close={setModalOpen}>
        {selectedInfo && (
          <ModalWrapper>
            <div className="all_career">
              <h5 className="heading">{"Career"}</h5>
              <div className="sections">
                <h4>Personal Information</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Full Name:</p>
                    <h5>{selectedInfo?.fullname}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Email:</p>
                    <h5>{selectedInfo?.email}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Phone:</p>
                    <h5>{selectedInfo?.phone}</h5>
                  </div>
                  <div className="inner_info">
                    <p>State:</p>
                    <h5>{selectedInfo?.state ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Address:</p>
                    <h5>{selectedInfo?.address ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Date Submitted:</p>
                    <h5>
                      {moment(selectedInfo?.createdAt).format("MMM Do, YYYY")}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="sections">
                <h4>Employment Information</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Role:</p>
                    <h5>{selectedInfo?.employment?.position ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Days Available:</p>
                    <h5>
                      {selectedInfo?.employment?.days_available[0] ?? "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Desired Salary:</p>
                    <h5>{selectedInfo?.employment?.desired_salary ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Available for shift:</p>
                    <h5>
                      {selectedInfo?.employment?.shift_available ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Has Transportation:</p>
                    <h5>
                      {selectedInfo?.employment?.transportation ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Available to work Overtime:</p>
                    <h5>
                      {selectedInfo?.employment?.work_overtime ? "Yes" : "No"}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="sections">
                <h4>Related Personal Information</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Have Accomodation:</p>
                    <h5>
                      {" "}
                      {selectedInfo?.personal?.isAccomodation ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Has applied to Glorious Service:</p>
                    <h5>{selectedInfo?.personal?.isApplied ? "Yes" : "No"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Has friend in Glorious Service:</p>
                    <h5>
                      {selectedInfo?.personal?.isFriendsWorking ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Is Older than 18:</p>
                    <h5>{selectedInfo?.personal?.isOlder ? "Yes" : "No"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Is U.S Citizen:</p>
                    <h5>
                      {selectedInfo?.personal?.isUsCitizen ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Has Criminal record:</p>
                    <h5>{selectedInfo?.personal?.isCriminal ? "Yes" : "No"}</h5>
                  </div>
                </div>
              </div>
              <div className="sections">
                <h4>Previous Employment Information</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Job Title:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.job_title ?? "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>State:</p>
                    <h5>{selectedInfo?.previous_employment?.state ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>City:</p>
                    <h5>{selectedInfo?.previous_employment?.city ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>ZIP:</p>
                    <h5>{selectedInfo?.previous_employment?.zip ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Employer Name:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.employer_name ??
                        "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Employer Phone:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.employer_phone ??
                        "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Employer Address:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.employer_address ??
                        "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Supervisor Name:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.supervisor_name ??
                        "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Reason for Leaving:</p>
                    <h5>
                      {selectedInfo?.previous_employment?.reason_for_leaving ??
                        "N/A"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Date :</p>
                    <h5>
                      {moment(
                        selectedInfo?.previous_employment?.date ??
                          selectedInfo?.createdAt
                      ).format("MMM Do, YYYY")}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="sections">
                <h4>Military Information</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Is Armed Forces:</p>
                    <h5>
                      {selectedInfo?.military?.isArmedForces ? "Yes" : "No"}
                    </h5>
                  </div>
                  <div className="inner_info">
                    <p>Branch:</p>
                    <h5>{selectedInfo?.military?.branch ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Rank:</p>
                    <h5>{selectedInfo?.military?.rank ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Years of Service:</p>
                    <h5>{selectedInfo?.military?.years_of_service ?? "N/A"}</h5>
                  </div>
                </div>
              </div>
              <div className="sections">
                <h4>References</h4>
                <div className="details">
                  <div className="inner_info">
                    <p>Reference Name:</p>
                    <h5>{selectedInfo?.references?.reference ?? "N/A"}</h5>
                  </div>
                  <div className="inner_info">
                    <p>Reference Contact:</p>
                    <h5>{selectedInfo?.references?.contact ?? "N/A"}</h5>
                  </div>
                </div>
              </div>
            </div>
          </ModalWrapper>
        )}
      </Modal>
    </Wrapper>
  );
};

export default Career;
