import { FaLocationArrow, FaBriefcase, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/Job";
import { useDispatch, useSelector } from "react-redux";
import JobInfo from "./JobInfo";
import moment from "moment";
import { deleteJob, setEditJob } from "../features/job/jobSlice";
import { applyJob } from "../features/allJobs/allJobsSlice";

const Job = ({
  _id,
  position,
  company,
  duration,
  location,
  type,
  createdAt,
  status,
  applied_users,
  assigned,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const date = moment(createdAt).format("MMM Do, YYYY");
  return (
    <Wrapper>
      <header>
        <div className="main-icon">{company.charAt(0)}</div>
        <div className="info">
          <h5>{position}</h5>
          <p>{company}</p>
        </div>
        <Link to={`/job/${_id}`}>
          <p className="">View Details</p>
        </Link>
      </header>
      <div className="content">
        <div className="content-center">
          <JobInfo icon={<FaLocationArrow />} text={location} />
          <JobInfo icon={<FaCalendarAlt />} text={date} />
          <JobInfo icon={<FaBriefcase />} text={type} />
          <div className={`status ${status}`}>{status}</div>
        </div>
        <footer>
          <div className="actions">
            {user?.isAdmin ? (
              status === "completed" ? (
                <p>This Job has been completed</p>
              ) : (
                <>
                  <Link
                    to="/add-job"
                    className="btn edit-btn"
                    onClick={() =>
                      dispatch(
                        setEditJob({
                          editJobId: _id,
                          position,
                          company,
                          location,
                          duration,
                          type,
                          status,
                        })
                      )
                    }
                  >
                    Edit
                  </Link>
                  <button
                    type="button"
                    className="btn delete-btn"
                    onClick={() => dispatch(deleteJob(_id))}
                  >
                    delete
                  </button>
                </>
              )
            ) : assigned === user?.id ? (
              <p>This Job is assigned to you</p>
            ) : applied_users?.length > 0 ? (
              applied_users?.includes(user?.id) ? (
                <p>You Applied for this Job</p>
              ) : (
                <button
                  onClick={() =>
                    dispatch(applyJob({ jobId: _id, userId: user?.id }))
                  }
                  type="button"
                  className="btn"
                >
                  Apply
                </button>
              )
            ) : (
              <button
                onClick={() =>
                  dispatch(applyJob({ jobId: _id, userId: user?.id }))
                }
                type="button"
                className="btn"
              >
                Apply
              </button>
            )}
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};
export default Job;
