import styled from "styled-components";

const Wrapper = styled.div`
  h5,
  p,
  h6 {
    margin: 0;
  }
  .heading {
    text-align: center;
  }
  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 10px 0 20px;
  }
  .inner_info {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

export default Wrapper;
