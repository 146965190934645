import { FormRow, FormRowSelect } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  handleChange,
  clearValues,
  createJob,
  editJob,
} from "../../features/job/jobSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const AddJob = () => {
  const {
    isLoading,
    position,
    company,
    location,
    type,
    jobTypeOptions,
    status,
    duration,
    isEditing,
    editJobId,
  } = useSelector((store) => store.job);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const history = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!position || !company || !location) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditing) {
      dispatch(
        editJob({
          jobId: editJobId,
          job: {
            position,
            company,
            location,
            type,
            duration,
          },
        })
      );
      return;
    }
    dispatch(
      createJob({
        position,
        company,
        location,
        type,
        duration,
      })
    );
  };

  const handleJobInput = (e) => {
    const { name, value } = e.target;
    dispatch(handleChange({ name, value }));
  };

  useEffect(() => {
    if (!isEditing) {
      dispatch(
        handleChange({
          name: "location",
          value: user.location,
        })
      );
    }
  }, []);

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? "edit job" : "add job"}</h3>
        <div className="form-center">
          {/* position */}
          <FormRow
            type="text"
            name="position"
            value={position}
            placeholder="e.g Medical Assitant, Cleaner ..."
            handleChange={handleJobInput}
          />
          {/* company */}
          <FormRow
            type="text"
            name="company"
            placeholder="e.g Frontline Ltd..."
            value={company}
            handleChange={handleJobInput}
          />
          {/* location */}
          <FormRow
            type="text"
            name="location"
            labelText="job location"
            placeholder="e.g Philadephia, Las Vegas..."
            value={location}
            handleChange={handleJobInput}
          />
          <FormRow
            type="text"
            name="duration"
            labelText="job duration"
            placeholder="e.g 8 hours..."
            value={duration}
            handleChange={handleJobInput}
          />
          {/* status */}
          {/* <FormRowSelect
            name="status"
            value={status}
            handleChange={handleJobInput}
            list={statusOptions}
          /> */}
          {/* job type*/}
          <FormRowSelect
            name="type"
            labelText="job type"
            value={type}
            handleChange={handleJobInput}
            list={jobTypeOptions}
          />
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button>
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default AddJob;
