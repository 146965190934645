import jwtDecode from "jwt-decode";
export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};
export const getToken = () => {
  const token = JSON.parse(localStorage.getItem("user"));
  return token;
};
export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  if (result) {
    const initial = result ? JSON.parse(result) : null;
    try {
      const user = jwtDecode(initial);
      return user;
    } catch (error) {}
  }
  return null;
};
