import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #24222280;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 100px;

  .modal_body {
    background: white;
    padding: 15px 30px;
    position: relative;
    width: 100%
    border-radius: 20px;
    overflow-y: auto;
  }
  .clos_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px;
    width: max-content;
    height: max-content;
    cursor: pointer;
  }
`;

export default Wrapper;
