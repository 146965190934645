import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  acceptJobThunk,
  applyJobThunk,
  getAllJobsThunk,
  getSingleThunk,
  getStatThunk,
  markJobCompleteThunk,
  markJobDoneThunk,
  rejectJobThunk,
} from "./allJobsThunk";

const initialFiltersState = {
  search: "",
  searchStatus: "all",
  searchType: "all",
  sort: "latest",
  sortOptions: ["latest", "oldest"],
};

const initialState = {
  isLoading: true,
  jobs: [],
  totalJobs: 0,
  numOfPages: 1,
  page: 1,
  stats: [],
  monthlyApplications: [],
  singleJob: null,
  ...initialFiltersState,
};

export const getAllJobs = createAsyncThunk("allJobs/getJobs", getAllJobsThunk);

export const showStats = createAsyncThunk("allJobs/showStats", getStatThunk);

export const getSingleJob = createAsyncThunk(
  "job/getSingleJob",
  getSingleThunk
);
export const applyJob = createAsyncThunk("job/applyJob", applyJobThunk);

export const acceptJob = createAsyncThunk("job/acceptJob", acceptJobThunk);

export const rejectJob = createAsyncThunk("job/rejectJob", rejectJobThunk);

export const markJobDone = createAsyncThunk(
  "job/markJobDone",
  markJobDoneThunk
);
export const markJobComplete = createAsyncThunk(
  "job/markJobComplete",
  markJobCompleteThunk
);

const allJobsSlice = createSlice({
  name: "allJobs",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllJobsState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllJobs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJobs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.jobs = payload.jobs;
        state.numOfPages = payload.numOfPages;
        state.totalJobs = payload.totalJobs;
      })
      .addCase(getAllJobs.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(showStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(showStats.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.stats = payload.data;
      })
      .addCase(showStats.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getSingleJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleJob.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.singleJob = payload?.data;
      })
      .addCase(getSingleJob.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(applyJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applyJob.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
      })
      .addCase(applyJob.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error);
      })
      .addCase(acceptJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptJob.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
      })
      .addCase(acceptJob.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error);
      })
      .addCase(rejectJob.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rejectJob.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
      })
      .addCase(rejectJob.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error);
      })
      .addCase(markJobDone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markJobDone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
      })
      .addCase(markJobDone.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error);
      })
      .addCase(markJobComplete.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markJobComplete.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload?.message);
      })
      .addCase(markJobComplete.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.error);
      });
  },
});

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllJobsState,
} = allJobsSlice.actions;

export default allJobsSlice.reducer;
