import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import {
  getCareer,
  getHealthCare,
  getHospitality,
  getTransportation,
  hideLoading,
  showLoading,
} from "./formSlice";

export const getHealthCareThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/healthcare`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getTransportationThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/transport`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getHospitalityThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/hospitality`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getCareerThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/career`);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteHealthCareThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/healthcare?id=${id}`);
    thunkAPI.dispatch(getHealthCare());
    return resp.data.message;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteTransportationThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/transport?id=${id}`);
    thunkAPI.dispatch(getTransportation());
    return resp.data.message;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteHospitalityThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/hospitality?id=${id}`);
    thunkAPI.dispatch(getHospitality());
    return resp.data.message;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteCareerThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/career?id=${id}`);
    thunkAPI.dispatch(getCareer());
    return resp.data.message;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
