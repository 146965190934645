import { useState, useEffect } from "react";
import { Logo, FormRow } from "../components";
import Wrapper from "../assets/wrappers/RegisterPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, registerUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { checkPassword, validateEmail } from "../utils/validation";

const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
};

function Register() {
  const [values, setValues] = useState(initialState);
  const [isMember, setIsMember] = useState(true);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { firstname, email, password, lastname } = values;
    if (!email || !password || (!isMember && !lastname && !firstname)) {
      toast.error("Please fill out all fields");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Kindly input a valid email");
      return;
    }
    if (!checkPassword(password)) {
      toast.error("Password must contain a number");
      return;
    }
    if (password.length < 8) {
      toast.error("Password must be 8 or more characters");
      return;
    }
    if (isMember) {
      return dispatch(loginUser({ email, password }));
    }
    dispatch(registerUser({ firstname, lastname, email, password }));
  };

  const toggleMember = () => {
    setIsMember((prev) => !prev);
  };
  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [user]);
  return (
    <Wrapper className="full-page">
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>{isMember ? "Login" : "Register"}</h3>
        {/* name field */}
        {!isMember && (
          <FormRow
            type="text"
            name="firstname"
            value={values.firstname}
            handleChange={handleChange}
          />
        )}
        {!isMember && (
          <FormRow
            type="text"
            name="lastname"
            value={values.lastname}
            handleChange={handleChange}
          />
        )}
        {/* email field */}
        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />
        {/* password field */}
        <FormRow
          type="password"
          name="password"
          value={values.password}
          handleChange={handleChange}
        />
        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {isLoading ? "loading..." : "submit"}
        </button>
        <p>
          {isMember ? "Not a member yet?" : "Already a member?"}
          <button type="button" onClick={toggleMember} className="member-btn">
            {isMember ? "Register" : "Login"}
          </button>
        </p>
      </form>
    </Wrapper>
  );
}
export default Register;
