import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #24222280;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_body {
    background: white;
    padding: 15px;
    position: relative;
    min-width: 400px;
    min-height: 200px;
    border-radius: 10px;
  }
  .clos_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: max-content;
    height: max-content;
    cursor: pointer;
  }
`;

export default Wrapper;
