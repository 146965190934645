import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import adminLinks from "../utils/links";
import userLinks from "../utils/userLinks";

const NavLinks = ({ toggleSidebar }) => {
  const { user } = useSelector((store) => store.user);
  return (
    <div className="nav-links">
      {user?.isAdmin
        ? adminLinks.map((link) => {
            const { text, path, id, icon } = link;
            return (
              <NavLink
                to={path}
                className={({ isActive }) => {
                  return isActive ? "nav-link active" : "nav-link";
                }}
                key={id}
                onClick={toggleSidebar}
                end
              >
                <span className="icon">{icon}</span>
                {text}
              </NavLink>
            );
          })
        : userLinks.map((link) => {
            const { text, path, id, icon } = link;
            return (
              <NavLink
                to={path}
                className={({ isActive }) => {
                  return isActive ? "nav-link active" : "nav-link";
                }}
                key={id}
                onClick={toggleSidebar}
                end
              >
                <span className="icon">{icon}</span>
                {text}
              </NavLink>
            );
          })}
    </div>
  );
};
export default NavLinks;
