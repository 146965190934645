import { useEffect } from "react";
import Job from "./Job";
import Wrapper from "../assets/wrappers/JobsContainer";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import { getAllJobs } from "../features/allJobs/allJobsSlice";
import PageBtnContainer from "./PageBtnContainer";
const JobsContainer = () => {
  const {
    jobs,
    isLoading,
    page,
    totalJobs,
    numOfPages,
    search,
    searchStatus,
    searchType,
    sort,
  } = useSelector((store) => store.allJobs);
  const dispatch = useDispatch();
  const { id, isAdmin } = useSelector((store) => store.user.user);
  useEffect(() => {
    dispatch(getAllJobs());
  }, [page, search, searchStatus, searchType, sort]);

  if (isLoading) {
    return <Loading />;
  }

  if (jobs.length === 0) {
    return (
      <Wrapper>
        <h2>No jobs to display...</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isAdmin ? (
        <>
          <h5>
            {totalJobs} job{jobs.length > 1 && "s"} found
          </h5>
          <div className="jobs">
            {jobs.map((job) => {
              return <Job key={job._id} {...job} />;
            })}
          </div>
        </>
      ) : (
        <>
          <h5>
            {
              jobs
                ?.filter((job) => job?.status !== "completed")
                ?.filter((j) => {
                  if (j?.assigned) {
                    if (j.assigned === id) {
                      return j;
                    }
                    return null;
                  }
                  return j;
                }).length
            }{" "}
            job
            {jobs
              ?.filter((job) => job?.status !== "completed")
              ?.filter((j) => {
                if (j?.assigned) {
                  if (j.assigned === id) {
                    return j;
                  }
                  return null;
                }
                return j;
              }).length > 1 && "s"}{" "}
            found
          </h5>
          <div className="jobs">
            {jobs
              ?.filter((job) => job?.status !== "completed")
              ?.filter((j) => {
                if (j?.assigned) {
                  if (j.assigned === id) {
                    return j;
                  }
                  return null;
                }
                return j;
              })
              .map((job) => {
                return <Job key={job._id} {...job} />;
              })}
          </div>
        </>
      )}

      {numOfPages > 1 && <PageBtnContainer />}
    </Wrapper>
  );
};
export default JobsContainer;
