import styled from "styled-components";

const Wrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;

  h1 {
    text-align: center;
  }
  .form_table {
    border: 2px solid darkgray;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
  }
  .table {
    width: 100%;
    overflow: auto;
    position: relative;

    .table_head {
      text-transform: uppercase;
      th {
        padding-left: 30px;
        padding-right: 20px;
      }
    }
    .table_body {
      padding: 15px;
      overflow-x: auto;
      tr {
        :hover {
          background: #c2c0c04a;
        }
        h5,
        p {
          margin: 0;
        }
        td {
          padding: 20px 30px;

          .buttons_cta {
            display: flex;
            gap: 8px;
          }
          .buttons_cta button {
            padding: 7px 12px;
            border-radius: 10px;
            font-weight: 500;
            cursor: pointer;
            outline: none;
            border: none;
          }
          .open {
            background: green;
            color: white;
          }
          .del {
            background: red;
            color: white;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding: 10px;
    h1 {
      font-size: 18px;
    }
    h4 {
      font-size: 14px;
    }
  }
`;

export default Wrapper;
