import { FaTimesCircle } from "react-icons/fa";
import Wrapper from "../assets/wrappers/CareerModal";

const CareerModal = ({ open = false, close, children }) => {
  return (
    <>
      {open ? (
        <Wrapper>
          <div className="modal_body">
            <span onClick={() => close(false)} className="clos_btn">
              <FaTimesCircle />
            </span>
            {children}
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

export default CareerModal;
