import { IoBarChartSharp } from "react-icons/io5";
import { MdQueryStats } from "react-icons/md";
import {
  FaBookMedical,
  FaCarAlt,
  FaClipboardList,
  FaHandHoldingMedical,
  FaWpforms,
} from "react-icons/fa";
import { ImProfile } from "react-icons/im";

const adminLinks = [
  { id: 1, text: "stats", path: "/", icon: <IoBarChartSharp /> },
  { id: 2, text: "all jobs", path: "all-jobs", icon: <MdQueryStats /> },
  { id: 3, text: "add job", path: "add-job", icon: <FaWpforms /> },
  { id: 4, text: "profile", path: "profile", icon: <ImProfile /> },
  { id: 5, text: "health form", path: "health", icon: <FaBookMedical /> },
  { id: 6, text: "transportation form", path: "transport", icon: <FaCarAlt /> },
  { id: 7, text: "career form", path: "career", icon: <FaClipboardList /> },
  {
    id: 8,
    text: "hospitality form",
    path: "hospitality",
    icon: <FaHandHoldingMedical />,
  },
];

export default adminLinks;
