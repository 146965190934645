import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteCareerThunk,
  deleteHealthCareThunk,
  deleteHospitalityThunk,
  deleteTransportationThunk,
  getCareerThunk,
  getHealthCareThunk,
  getHospitalityThunk,
  getTransportationThunk,
} from "./formThunk";

const initialState = {
  healthcare: [],
  isLoading: true,
  hospitality: [],
  career: [],
  transportation: [],
};

export const getHealthCare = createAsyncThunk(
  "allJobs/getHealthCare",
  getHealthCareThunk
);
export const getHospitality = createAsyncThunk(
  "allJobs/getHospitality",
  getHospitalityThunk
);
export const getTransportation = createAsyncThunk(
  "allJobs/getTransportation",
  getTransportationThunk
);
export const getCareer = createAsyncThunk("allJobs/getCareer", getCareerThunk);
export const deleteCareer = createAsyncThunk(
  "allJobs/deleteCareer",
  deleteCareerThunk
);
export const deleteHealthCare = createAsyncThunk(
  "allJobs/deleteHealthCare",
  deleteHealthCareThunk
);
export const deleteHospitality = createAsyncThunk(
  "allJobs/deleteHospitality",
  deleteHospitalityThunk
);
export const deleteTransportation = createAsyncThunk(
  "allJobs/deleteTransportation",
  deleteTransportationThunk
);

const allFormsSlice = createSlice({
  name: "allforms",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHealthCare.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHealthCare.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.healthcare = payload.data;
      })
      .addCase(getHealthCare.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getHospitality.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHospitality.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.hospitality = payload.data;
      })
      .addCase(getHospitality.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getTransportation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransportation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.transportation = payload.data;
      })
      .addCase(getTransportation.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getCareer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCareer.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.career = payload.data;
      })
      .addCase(getCareer.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteCareer.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteCareer.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(deleteHealthCare.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteHealthCare.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(deleteHospitality.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteHospitality.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(deleteTransportation.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteTransportation.rejected, (state, { payload }) => {
        toast.error(payload);
      });
  },
});

export const { showLoading, hideLoading } = allFormsSlice.actions;

export default allFormsSlice.reducer;
