import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../assets/wrappers/HealthCare";
import ModalWrapper from "../../assets/wrappers/ModalInfo";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import {
  deleteHealthCare,
  getHealthCare,
} from "../../features/forms/formSlice";

const HealthCare = () => {
  const { healthcare, isLoading } = useSelector((store) => store.forms);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHealthCare());
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Wrapper>
      <div className="healthcare">
        <h1>Health Care Forms</h1>
        <div className="form_table">
          <div className="form_header">
            <h4>All Submitted Health Care Forms</h4>
          </div>
          <div className="form_body">
            <table className=" table ">
              <thead className="table_head">
                <tr>
                  <th scope="col">
                    <p>Name</p>
                  </th>
                  <th scope="col">
                    <p>Email</p>
                  </th>
                  <th scope="col">
                    <p>Phone</p>
                  </th>
                  <th scope="col">
                    <p>Message</p>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="table_body">
                {healthcare?.map((info, index) => (
                  <tr key={index}>
                    <td>
                      <h5>{info?.firstname + " " + info?.lastname ?? ""}</h5>
                    </td>
                    <td>
                      <p>{info?.email}</p>
                    </td>
                    <td>
                      <p>{info?.phone}</p>
                    </td>
                    <td>
                      <p>{info?.message?.slice(0, 21)}...</p>
                    </td>

                    <td>
                      <div className="buttons_cta">
                        <button
                          onClick={() => {
                            setSelectedInfo(info);
                            setModalOpen(true);
                          }}
                          className="open"
                        >
                          Open
                        </button>
                        <button
                          onClick={() => dispatch(deleteHealthCare(info?._id))}
                          className="del"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal open={modalOpen} close={setModalOpen}>
        {selectedInfo && (
          <ModalWrapper>
            <h5 className="heading">
              {selectedInfo?.firstname + " " + selectedInfo?.lastname ?? ""}
            </h5>
            <div className="details">
              <div className="inner_info">
                <h6>Email:</h6>
                <p>{selectedInfo?.email}</p>
              </div>
              <div className="inner_info">
                <h6>Phone:</h6>
                <p>{selectedInfo?.phone}</p>
              </div>
              <div className="inner_info">
                <h6>Number of Days:</h6>
                <p>{selectedInfo?.no_of_days}</p>
              </div>
              <div className="inner_info">
                <h6>Number of health workers:</h6>
                <p>{selectedInfo?.health_attendants}</p>
              </div>
              <div className="inner_info">
                <h6>Date Submitted:</h6>
                <p>{moment(selectedInfo?.createdAt).format("MMM Do, YYYY")}</p>
              </div>
            </div>
            <div className="">
              <h6>Message:</h6>
              <p>{selectedInfo?.message}</p>
            </div>
          </ModalWrapper>
        )}
      </Modal>
    </Wrapper>
  );
};

export default HealthCare;
