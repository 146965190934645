import moment from "moment";
import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Wrapper from "../../assets/wrappers/SingleJobPage";
import Loading from "../../components/Loading";
import {
  acceptJob,
  applyJob,
  getSingleJob,
  markJobComplete,
  markJobDone,
  rejectJob,
} from "../../features/allJobs/allJobsSlice";

const SingleJob = () => {
  const { singleJob, isLoading } = useSelector((store) => store.allJobs);
  const { user } = useSelector((store) => store.user);
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleJob({ jobId: params.id }));
  }, []);
  const date = moment(singleJob?.createdAt).format("MMM Do, YYYY");
  if (isLoading) {
    return <Loading />;
  }

  if (singleJob === null && !isLoading) {
    return (
      <Wrapper>
        <div className="no_data">
          <h2>This Job doesn't exist or has been deleted</h2>
          <Link to={"/all-jobs"} className="to_all">
            Go Back
          </Link>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <div className="go_back">
        <Link to={"/all-jobs"}>
          <FaArrowLeft />
          <p className="">Go Back</p>
        </Link>
      </div>
      <div className="major_title">
        <h1 className="title">{singleJob?.position}</h1>
      </div>
      <div className="all_contents">
        {user?.isAdmin && singleJob?.status === "done" && (
          <div className="done_cta">
            <h4>This Job has been marked done</h4>
            <button
              onClick={() =>
                dispatch(
                  markJobComplete({
                    jobId: singleJob?._id,
                    userId: singleJob?.assigned?._id,
                  })
                )
              }
              className="mark"
            >
              Mark Completed
            </button>
          </div>
        )}
        <>
          {!user?.isAdmin && (
            <>
              {singleJob?.assigned?._id === user?.id ? (
                <div className="done_cta">
                  <h4>This Job has been assigned to you</h4>
                  {singleJob?.status === "done" ? (
                    <div>
                      <h5>Job marked as Done</h5>
                      <p>Kindly wait to be marked completed</p>
                    </div>
                  ) : singleJob?.status === "completed" ? (
                    <p>Job Completed</p>
                  ) : (
                    <button
                      onClick={() =>
                        dispatch(
                          markJobDone({
                            jobId: singleJob?._id,
                          })
                        )
                      }
                      className="mark"
                    >
                      Mark Done
                    </button>
                  )}
                </div>
              ) : singleJob?.applied_users?.some((v) => v?._id === user?.id) ? (
                <div className="">
                  <h4>You applied for this job</h4>
                  <p>Kindly wait for approval</p>
                </div>
              ) : (
                <div className="done_cta">
                  <h4>This job has not been assigned</h4>
                  <button
                    onClick={() =>
                      dispatch(
                        applyJob({ jobId: singleJob?._id, userId: user?.id })
                      )
                    }
                    className="btn"
                  >
                    Apply
                  </button>
                </div>
              )}
            </>
          )}
        </>
        <div className="job_details">
          <h4>Job Details</h4>
          <div className="top_content">
            <div className="inner_top_content">
              <h5>Company:</h5>
              <h4>{singleJob?.company}</h4>
            </div>
            <div className="inner_top_content">
              <h5>Status:</h5>
              <h4>{singleJob?.status}</h4>
            </div>
            <div className="inner_top_content">
              <h5>Created on:</h5>
              <h4>{date}</h4>
            </div>
            <div className="inner_top_content">
              <h5>Job Duration:</h5>
              <h4>{singleJob?.duration}</h4>
            </div>
            <div className="inner_top_content">
              <h5>Assign to:</h5>
              <h4>
                {singleJob?.assigned
                  ? singleJob?.assigned?.firstname +
                      " " +
                      singleJob?.assigned?.lastname ?? ""
                  : "Not Yet Assigned"}
              </h4>
            </div>
            <div className="inner_top_content">
              <h5>Location:</h5>
              <h4>{singleJob?.location}</h4>
            </div>
            <div className="inner_top_content">
              <h5>Type:</h5>
              <h4>{singleJob?.type}</h4>
            </div>
          </div>
        </div>
        {singleJob?.description && (
          <div className="job_details">
            <h4>Job Description</h4>
            <div className="job_description">
              <p>{singleJob?.description}</p>
            </div>
          </div>
        )}
        {user?.isAdmin && (
          <div className="job_applied_users">
            <h4>Applied Users</h4>
            <div className="job_description">
              {singleJob?.applied_users?.length > 0 ? (
                <div className="">
                  {singleJob?.applied_users?.map((user, index) => (
                    <div key={index} className="applied_user">
                      <div className="name_cont">
                        <span>
                          {user?.firstname?.charAt(0)?.toUpperCase() +
                          user?.lastname
                            ? user?.lastname.charAt(0)?.toUpperCase()
                            : user?.firstname?.charAt(1)?.toUpperCase()}
                        </span>
                        <h4 className="">
                          {user?.firstname + " " + user?.lastname ?? ""}
                        </h4>
                      </div>
                      <p className="">{user?.email}</p>
                      {singleJob?.assigned ? (
                        singleJob?.assigned?._id === user?._id ? (
                          singleJob?.status === "done" ||
                          singleJob?.status === "completed" ? null : (
                            <div className="ctas">
                              <button
                                onClick={() =>
                                  dispatch(
                                    rejectJob({
                                      jobId: singleJob?._id,
                                      userId: user?._id,
                                    })
                                  )
                                }
                                className="reject_cta"
                              >
                                Revoke
                              </button>
                            </div>
                          )
                        ) : null
                      ) : (
                        <div className="ctas">
                          <button
                            onClick={() =>
                              dispatch(
                                acceptJob({
                                  jobId: singleJob?._id,
                                  userId: user?._id,
                                })
                              )
                            }
                            className="accept_cta"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() =>
                              dispatch(
                                rejectJob({
                                  jobId: singleJob?._id,
                                  userId: user?._id,
                                })
                              )
                            }
                            className="reject_cta"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <h3 className="centerlized">No Submitted Application</h3>
              )}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default SingleJob;
