import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { getAllJobs, getSingleJob } from "./allJobsSlice";

export const getAllJobsThunk = async (_, thunkAPI) => {
  const { page, search, searchStatus, searchType, sort } =
    thunkAPI.getState().allJobs;

  let url = `/job/all-jobs?status=${searchStatus}&jobType=${searchType}&sort=${
    sort === "latest" ? -1 : 1
  }&page=${page}`;
  if (search) {
    url = url + `&search=${search}`;
  }
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getSingleThunk = async ({ jobId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/one?id=${jobId}`);
    // thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const applyJobThunk = async ({ jobId, userId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/apply`, { jobId, userId });
    thunkAPI.dispatch(getAllJobs());
    thunkAPI.dispatch(getSingleJob({ jobId }));
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const acceptJobThunk = async ({ jobId, userId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/accept`, { jobId, userId });
    thunkAPI.dispatch(getSingleJob({ jobId }));
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const rejectJobThunk = async ({ jobId, userId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/reject`, { jobId, userId });
    thunkAPI.dispatch(getSingleJob({ jobId }));
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const markJobDoneThunk = async ({ jobId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/done`, { jobId });
    thunkAPI.dispatch(getSingleJob({ jobId }));
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const markJobCompleteThunk = async ({ jobId, userId }, thunkAPI) => {
  try {
    const resp = await customFetch.post(`/job/complete`, { jobId, userId });
    thunkAPI.dispatch(getSingleJob({ jobId }));
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getStatThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.post("/job/stat");
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
