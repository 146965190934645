import styled from "styled-components";

const Wrapper = styled.div`
  h5,
  p,
  h6 {
    margin: 0;
  }
  .heading {
    text-align: center;
  }
  .all_career > * {
    margin-top: 20px;
  }
  .sections {
    border: 2px solid darkgray;
    border-radius: 10px;
    padding: 10px;
  }
  .details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 10px;
    padding: 10px 0 20px;
  }
  .inner_info {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  @media (max-width: 500px) {
  }
`;

export default Wrapper;
