import styled from "styled-components";

const Wrapper = styled.section`
  .no_data {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .go_back {
    color: black;
    background-color: gray;
    cursor: pointer;
  }
  .go_back,
  .go_back a {
    display: flex;
    gap: 10px;
    color: black;
    align-items: center;
    padding: 5px 7px;
    border-radius: 6px;
    width: max-content;
    p {
      margin: 0;
    }
    :hover {
      color: white;
      background-color: blue;
    }
  }
  .all_contents {
    > div:not(:first-child) {
      margin-top: 40px;
    }
  }
  .done_cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4,
    p,
    h5 {
      margin: 0;
    }
    .mark {
      padding: 5px 10px;
      border-radius: 8px;
      font-weight: 500;
      background: lightgreen;
      color: white;
      outline: none;
      border: none;
      cursor: pointer;
      :hover {
        background: darkgreen;
      }
    }
  }
  .top_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    border: 2px solid gray;
    border-radius: 8px;
    padding: 10px;
  }
  .inner_top_content {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
  }
  .job_description {
    border: 2px solid gray;
    border-radius: 8px;
    padding: 10px;
  }
  .applied_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 5px 0;
    padding: 5px;
    .name_cont {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        width: 40px;
        height: 40px;
        background-color: darkgray;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      h4 {
        margin: 0;
      }
    }
    .ctas {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        padding: 8px 15px;
        border-radius: 8px;
        font-weight: 500;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
    .accept_cta {
      background: green;
      color: white;
      :hover {
        background: darkgreen;
      }
    }
    .reject_cta {
      background: red;
      color: white;
      :hover {
        background: darkred;
      }
    }
  }

  .centerlized {
    text-align: center;
  }
`;

export default Wrapper;
